import React from "react";

import "./customSocialIcons.scss";

import twitter from "../../assets/images/twitter.png";

export default function CustomSocialIcons() {
  return (
    <div className="icon-container">
      <a
        href="https://twitter.com/SwagSociety_"
        style={{ backgtoundImage: `url(${twitter})` }}
        target="_blank"
      >
        <img src={twitter} alt="twitter" />
      </a>
    </div>
  );
}
