import { useState, useEffect } from "react";
import { useMetaMask } from "metamask-react";
import { ToastContainer, toast } from "react-toastify";
import loader from "./assets/images/loader.gif";
import "./app.scss";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/Header/Header";
import WalletLogin from "./components/WalletLogin/WalletLogin";
import MintPassScreen from "./components/MintPassScreen/MintPassScreen";

// Backend Imports
import { getWhitelist } from "./utils/backendApi";
import {
  getWhiteListDetails,
  maxsupply,
  providerHandler,
  totalMintedTokens,
  getOwnerReservedTokens,
  whiteListMintTracker,
} from "./web3/contractInteraction";
import CustomSocialIcons from "./components/CustomSocialIcons/CustomSocialIcons";
import { getDateFormate } from "./utils/getDateFormate";
import { ToasterProperties } from "./utils/toaster";

function App() {
  const { status, connect, account, chainId } = useMetaMask();

  const [passLoading, setPassLoading] = useState(false);

  const [isMintpossible, setIsMintPossible] = useState(false); //this for mint is started or ended.
  const [isMintPossibleMessage, setIsMintPossibleMessage] = useState("");

  const [signature, setSignature] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const [beginTime, setBeginTime] = useState(0);

  const handleChainChange = async (reload) => {
    const currentChain = await window.ethereum.request({
      method: "eth_chainId",
    });

    if (currentChain !== "0x1") {
      setPassLoading(true);

      toast("Switch to Ethereum Mainnet", ToasterProperties);

      window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }],
        })
        .then(() => {
          setPassLoading(false);
          if (reload) window.location.reload(false);
        });
    }
  };

  const startAndEndCheckHandler = (data) => {
    const currentTimeSeconds = parseInt(Date.now() / 1000);
    if (
      data.startTime < currentTimeSeconds &&
      data.endTime > currentTimeSeconds
    ) {
      return { check: true, execution: true };
    } else {
      if (data.startTime > currentTimeSeconds) {
        setIsMintPossibleMessage(`Sale will begin at`);
        setBeginTime(getDateFormate(data.startTime));

        return { check: true, execution: false };
      } else {
        setIsMintPossibleMessage("Sale is over.");
        return { check: false };
      }
    }
  };

  const accountSetup = async () => {
    setPassLoading(true);
    const account = await providerHandler();
    const userListType = await getWhitelist(account);

    const ownerTokens = await getOwnerReservedTokens();
    const totalMinted = await totalMintedTokens();
    const totalsupply = await maxsupply();
    const whiteListDetails = await getWhiteListDetails();
    if (userListType.listType === 1) {
      if (startAndEndCheckHandler(whiteListDetails).check) {
        if (!startAndEndCheckHandler(whiteListDetails).execution) {
          //STOP THE EXECUTION OF THE FUNCTION BECAUSE THE MINT IS NOT STARTED.

          setPassLoading(false);
          return;
        }
        const mintedTokensByUser = await whiteListMintTracker(account);
        console.log(mintedTokensByUser, totalMinted, totalsupply, ownerTokens);
        //CHECKING TOTAL TOKENS ARE MINTED
        if (totalMinted < totalsupply - ownerTokens) {
          //CHECKING USER EXHAUSTED THE WALLET QUOTA OR NOT
          if (mintedTokensByUser < whiteListDetails.buyLimitPerWallet) {
            setIsMintPossible(true);
            setSignature(userListType.signature);
          } else {
            setIsMintPossibleMessage("You have minted already !");
          }
        } else {
          setIsMintPossibleMessage("All sold out !");
        }
      }
    } else {
      setIsMintPossibleMessage("Sorry, you are not on the list.");
    }
    setPassLoading(false);
  };

  useEffect(() => {
    handleChainChange();
  }, [chainId]);
  useEffect(() => {
    if (account) {
      setIsMintPossible(false);
      setIsMintPossibleMessage("");
      setBeginTime(0);
      accountSetup();
    }
  }, [account, refresh]);

  return (
    <div className="app">
      <Header account={account} />
      {status === "connected" ? (
        passLoading ? (
          <div className="loader">
            <img src={loader} />
          </div>
        ) : (
          <MintPassScreen
            setPassLoading={setPassLoading}
            isMintpossible={isMintpossible}
            isMintPossibleMessage={isMintPossibleMessage}
            signature={signature}
            setRefresh={setRefresh}
            beginTime={beginTime}
          />
        )
      ) : (
        <WalletLogin connect={connect} status={status} />
      )}

      <div className="bg-cover">
        {!passLoading && status !== "connecting" && (
          <div className="logo"></div>
        )}
      </div>
      <CustomSocialIcons />
      {/* <SocialIcons /> */}
      <ToastContainer />
    </div>
  );
}

export default App;
