import React from "react";

import loader from "../../assets/images/loader.gif";

import "./walletLogin.scss";

export default function WalletLogin({ status, connect }) {
  const connectWallet = () => {
    connect();
  };
  return (
    <div className="wallet-login-container">
      {status === "initializing" || status === "connecting" ? (
        <div className="wallet-loading">
          <img src={loader} />
        </div>
      ) : null}
      {status === "unavailable" ? (
        <div className="metamask-unavailable">
          <h1>Install MetaMask to continue</h1>
        </div>
      ) : null}
      {status === "notConnected" ? (
        <div className="metamask-login">
          <button
            onClick={() => {
              connectWallet();
            }}
          >
            CONNECT
          </button>
        </div>
      ) : null}
    </div>
  );
}
