import React from "react";

import "./mintPassScreen.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { whiteListMint } from "../../web3/contractInteraction";

export default function MintPassScreen({
  setPassLoading,
  isMintpossible,
  isMintPossibleMessage,
  signature,
  setRefresh,
  beginTime,
}) {
  const mintHandler = async () => {
    setPassLoading(true);

    const data = await whiteListMint(signature, 1, setRefresh);

    setPassLoading(false);
  };

  console.log(isMintpossible);

  return (
    <div className="mint-pass-screen">
      {isMintpossible ? (
        <div className="mint-container">
          <button className="mint-button" onClick={mintHandler}>
            MINT
          </button>
        </div>
      ) : (
        <div className="error">
          <h1 className="messge">
            {isMintPossibleMessage} {!!beginTime && beginTime}
          </h1>
          {!beginTime && (
            <a
              href="https://opensea.io/collection/swag-society"
              target="_blank"
            >
              OpenSea
            </a>
          )}
          {/* {!!beginTime && <h1 className="beginTime">{beginTime}</h1>} */}
        </div>
      )}
    </div>
  );
}
