import { ethers } from "ethers";
import { toast } from "react-toastify";
import config from "./config.json";
import abi from "./abi.json";
import { ToasterProperties } from "../utils/toaster";

let contract;

const contractAddress = config.contractAddress;

export const providerHandler = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const account = await provider.listAccounts();
  const address = account[0];
  const signer = provider.getSigner();
  contract = new ethers.Contract(contractAddress, abi, signer);

  return address;
};

//read functions

export const getWhiteListDetails = async () => {
  const n = await contract.whiteListMint();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.buyLimitPerWallet.toNumber(),
    remainingTokens: n.remainingTokens.toNumber(),
  };
};

export const whiteListMintTracker = async (address) => {
  const n = await contract.whiteListMintTracker(address);
  return n.toNumber();
};

export const totalMintedTokens = async () => {
  const n = await contract.totalSupply();
  return n.toNumber();
};

export const maxsupply = async () => {
  const n = await contract.maxSupply();
  return n.toNumber();
};

export const getOwnerReservedTokens = async () => {
  const n = await contract.ownerRemainingCap();
  return n.toNumber();
};

//write functions

export const whiteListMint = async (signer, amount, setRefresh) => {
  try {
    const n = await contract.whitelistMint(signer, amount);
    await n.wait();
    toast.success("Transaction Successful!", ToasterProperties);
    setRefresh((prev) => !prev);
    return n;
  } catch (e) {
    console.log(e);
    toast.success(e.message, ToasterProperties);
    return false;
  }
};
